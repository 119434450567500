// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  // state = ตัวแปรที่อยู่ใน state/การประกาศตัวแปร
  state: {
    respData: null,
  },
  // getters = การจัดฟอแมต/เปลี่ยนค่า realtime
  getters: {
  },
  // mutation = เป็นฟังชั่นในการจัดการ state
  mutations: {
    GET(state, data) {
      state.respData = data
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  // action = event ใช้ในการเรียกมาจาก
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/logs/getUserLogs?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)// เอางานไปสั่งใน mutation
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // // eslint-disable-next-line no-unused-vars
    // add({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     axios.post('/api/web/agent/member/addMember', item)
    //       .then(response => {
    //         commit('ADD', response.data.data)
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // },
    // // eslint-disable-next-line no-unused-vars
    // update({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     axios.put('/api/web/agent/member/updateMember', item)
    //       .then(response => {
    //         commit('UPDATE', response.data.data)
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // },
    // eslint-disable-next-line no-unused-vars
    // delete({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     axios.delete(`/api/web/root/Members/deleteMember/${item}`)
    //       .then(response => {
    //         commit('REMOVE', item)
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // },
  },
}
